/* eslint-disable prettier/prettier */
import moment from "moment";
import {
  axiosTNL,
  axiosTNLService,
  axiosTNLServiceImages,
  axiosALTService
  //axiosNAV,
  //axiosTNLAPI
} from "./axiosInstance";

export default {
  // eslint-disable-next-line prettier/prettier
  getLimit(nos) {
    let splitId = nos.toString().split(",");
    let url = "";
    splitId.forEach(element => {
      if (url != "") url += "%20or%20";
      url += `No%20eq%20'${element}'`;
    });
    alert(url);
    console.log('axios TNL service')
    console.log(axiosALTService)

    return axiosALTService.get('', {
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
  },

  getVehicles(id) {
    //return axiosTNL.get("tdms/GetVehicleAvailability");
    // console log the concatenated string begginning with order/AvailableVehicles and the id variable
    console.log("order/AvailableVehicles/" + id);
    return axiosTNL.get(`order/AvailableVehicles/${id}`);
  },
  async getAllocation(dealerId) {
    /*
        axiosNAV.get(`AllocationCard?$filter=Customer_No%20eq%20'${dealerId}'&$format=json`, '', {
          headers: {
            // remove headers
          }
        }).then(res => {
          console.log('got a response')
          console.log(res);
          return res
        }).catch(err => {
          console.log('error is', err.response);
        });
        */

    /*
        const details = await axiosNAV.get(`AllocationCard?$filter=Customer_No%20eq%20'${dealerId}'&$format=json`)
        return details; //axiosNAV.get(`AllocationCard?$filter=Customer_No%20eq%20'${dealerId}'&$format=json`)
    */
    console.log("order/AvailableVehicles/" + dealerId);
    return axiosTNL.get(`order/Allocation/${dealerId}`);

    //return axiosTNLAPI.get(`GetAllocation/${dealerId}`)
  },
  placeOrder: async function (orderInfo) {
    console.log("order placed");
    //return axiosTNL.get("tdms/GetVehicleAvailability");
    return axiosTNL.post("order/PlaceOrder", orderInfo);
  },
  getVehicleUrl: async function (description) {
    return axiosTNLServiceImages.post(`ProcessImages/`, {
      make: "toyota",
      model: description,
      desiredHeight: "50"
    });
  },
  convertDate(date) {
    return moment(date).format("DD-MMM-YYYY hh:mm a");
  },
  getPendingOrders: async function (dealerID) {
    const url = `order/GetPendingOrders/${dealerID}`;
    return axiosTNL.get(url);
  },
  getOrdersRipeForLogistics: async function () {
    const url = `order/GetOrdersRipeForLogistics2`;
    return axiosTNL.get(url);
  },
  saveCustomDocs: async function (info) {
    return axiosTNL.post("order/UpdateCustomDocs", info);
  },
  getOrderYears: async function () {
    const url = `order/SharepointYear`;
    return axiosTNL.get(url);
  },
  getOrderFiles: async function (param) {
    const url = `order/SharepointFiles/${param}`;
    return axiosTNL.get(url);
  },
  getAdminPendingOrders: async function () {
    const url = `order/getOrdersPendingApproval`;
    return axiosTNL.get(url);
  },
  getAdminProcessedDiscounts: async function () {
    const url = `order/GetProcessedDiscounts`;
    return axiosTNL.get(url);
  },
  getOrderStatus: async function (dealerID) {
    const url = `order/GetProcessedOrders/${dealerID}`;
    return axiosTNL.get(url);
  },
  getProcessedOrders: async function (dealerID) {
    const url = `order/GetFinalisedOrders/${dealerID}`;
    return axiosTNL.get(url);
  },
  updateOrderStatus: async function (info) {
    return axiosTNL.post("order/UpdateOrderNotification", info);
  },
  updateAdminOrderStatus: async function (info) {
    return axiosTNL.post("order/ApproveDiscount", info);
  },
  getVehicleBalances: async function (dealerID) {
    const url = `order/VehicleBalances/${dealerID}`;
    return axiosTNL.get(url);
  },
  getAllBalances: async function () {
    const url = `order/AllBalances`;
    return axiosTNL.get(url);
  },
  getCreditLimit: async function (id) {
    const url = `order/GetDealerLimitJson/${id}`;
    return axiosTNL.get(url);
  },
  getAccountStatement(acctno) {
    const url = `order/InvoiceList/${acctno}`;
    return axiosTNL.get(url);
  },
  getAvailableDiscounts(id) {
    const url = `tnlservice/tdms/GetOrderDiscount/${id}`;
    return axiosTNLService.get(url);
  },

  getDetails(items) {
    //TODO: Replace with real API

    let itemNos = "";

    items.forEach((item, i) => {
      const prefix = i == 0 ? "" : ",";
      itemNos += prefix + item.ItemNo;
    });

    return axiosTNL.get(
      "https://testservice.tdmsportal.com/tdms/VehicleInfo?itemnos=" + itemNos
    );
  },

  getPartsDetails(partsNumbers) {
    return axiosTNL.get(
      "https://testservice.tdmsportal.com/tdms/PartsAvailabilityInfo?itemnos=" +
      partsNumbers
    );
  },

  getPartsDetails1(items) {
    //TODO: Replace with real API

    // Mock the API
    return new Promise((resolve) => {
      setTimeout(() => {
        const partsWithDetails = { ...items };

        Object.keys(partsWithDetails).forEach((partNo) => {
          partsWithDetails[partNo].PartName = "PLUG SPARK";
          partsWithDetails[partNo].UnitCost = "2550.10";
          partsWithDetails[partNo].SuggestedSubstitute = "sss";
        });

        resolve({ data: partsWithDetails });
      }, 1111);
    });
  },

  placeOrderVehicle(payload) {
    return axiosTNL.post(
      "https://testservice.tdmsportal.com/tdms/VehicleOrder",
      payload
    );
  },

  placeOrderParts(payload) {
    return axiosTNL.post(
      "https://testservice.tdmsportal.com/tdms/PartsOrder",
      payload
    );
  }
};
