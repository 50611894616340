import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    // =============================================================================
    // FULL LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("@/views/layouts/FullPage.vue"),
    children: [
      {
        path: "/",
        name: "Login",
        component: () => import("../views/account/Login.vue")
      },
      {
        path: "/admin",
        name: "Admin Login",
        component: () => import("../views/account/AdminLogin.vue")
      }
    ]
  },
  {
    // =============================================================================
    // FULL LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("@/views/layouts/Main.vue"),
    children: [
      {
        path: "/AccountStatus",
        name: "AccountStatus",
        component: () => import("../views/AccountStatus.vue")
      },
      {
        path: "/home",
        name: "Home",
        component: Home
      },
      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue")
      },
      {
        path: "/coupons",
        name: "Coupons",
        component: () => import("../views/Coupons.vue")
      },
      {
        path: "/pending",
        name: "Pending",
        component: () => import("../views/Pending.vue")
      },
      {
        path: "/OrderStatus",
        name: "OrderStatus",
        component: () => import("../views/OrderStatus.vue")
      },
      {
        path: "/processed",
        name: "Processed",
        component: () => import("../views/Processed.vue")
      },
      {
        path: "/adpending",
        name: "Pending",
        component: () => import("../views/admin/Pending.vue")
      },
      {
        path: "/adprocessed",
        name: "Processed",
        component: () => import("../views/admin/Processed.vue")
      },
      {
        path: "/adlogistics",
        name: "CustomsDocument",
        component: () => import("../views/logistics/Processed.vue")
      },
      {
        path: "/PlaceOrder",
        name: "PlaceOrder",
        component: () => import("../views/PlaceOrder.vue")
      },
      {
        path: "/PartsAvailability",
        name: "PartsAvailability",
        component: () => import("../views/PartsAvailability.vue")
      },
      {
        path: "/PartsOrdering",
        name: "PartsOrdering",
        component: () => import("../views/PartsOrdering.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
