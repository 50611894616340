export const authUser = key => {
  var user = JSON.parse(localStorage.getItem("userInfo"));
  if (user) {
    return user[key];
  }
};

export const storeData = (key, data) => {
  localStorage.removeItem(key);
  localStorage.setItem(key, data);
};

export const getData = key => {
  return JSON.parse(localStorage.getItem(key));
};
